.cmn-btn {
  display: none;
  text-align: center;
  margin-top: 5%;
}

@media only screen and (max-width: 525px) {
  .cmn-btn {
    margin-left: 25%;
    margin-bottom: 5%;
    display: block !important;
  }
}

@media only screen and (max-width: 375px) {
  .cmn-btn {
    display: block !important;
  }
}

@media only screen and (max-width: 320px) {
  .cmn-btn {
    margin-left: 3%;
    display: block !important;
  }
}

.contest-card__content .cmn-btn {
  display: none;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .contest-card__content .cmn-btn {
    margin-left: 17%;
    margin-bottom: 5%;
    display: block !important;
  }
}

@media only screen and (max-width: 375px) {
  .contest-card__content .cmn-btn {
    margin-left: 14%;
  }
}

@media only screen and (max-width: 320px) {
  .contest-card__content .cmn-btn {
    margin-left: 3%;
  }
}
